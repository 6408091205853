<template>
  <div id="app">
    <transition
      name="slide-fade"
      mode="out-in"
    >
      <router-view />
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import { hotjarMixin } from '../src/mixins/hotjarMixin';

export default {
  name: 'App',

  mixins: [ hotjarMixin ],

  data: () => ({
    //
  }),

  computed: {
    privacyPoliciesURL() {
      switch(process.env.VUE_APP_KEYCLOAK_REALM) {
        case 'Uppersoft':
          return 'https://upcities.app/politica-privacidade-pmvv';
        case 'pmvv':
          return 'https://upcities.app/politica-privacidade-pmvv';
        case 'pmcariacica':
          return 'https://upcities.app/politica-privacidade-cariacica';
        case 'oabes':
          return '#';
        case 'pmci':
          return 'https://upcities.app/politica-privacidade-cachoeiro-itapemirim';
        default :
        return '#';
      }
    }
  },

  metaInfo() {
    return {
      titleTemplate: `%s | ${this.$t('CITY.pageTitle')} ${this.$t('CITY.name')}`,

      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: `Agende serviços da Prefeitura Municipal de ${this.$t('CITY.name')} com muita praticidade.`,
          },

          // OpenGraph
          {
            vmid: 'og:title',
            property: 'og:title',
            content: `${this.$t('CITY.pageTitle')} ${this.$t('CITY.name')}`,
          },
          {
            vmid: 'og:site_name',
            property: 'og:site_name',
            content: this.$t('CITY.name'),
          },
          {
            vmid: 'og:type',
            property: 'og:type',
            content: 'website',
          },
          {
            vmid: 'og:url',
            property: 'og:url',
            content: `${window.location.origin}`,
          },
          {
            vmid: 'og:image',
            property: 'og:image',
            content: require(`@/assets/theme/${process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft' ? 'upcities' : process.env.VUE_APP_KEYCLOAK_REALM}/icons/og/meta.jpg`),
          },
          {
            vmid: 'og:description',
            property: 'og:description',
            content: `Agende serviços da Prefeitura Municipal de ${this.$t('CITY.name')} com muita praticidade.`,
          },

          // Twitter
          {
            vmid: 'twitter:card',
            name: 'twitter:card',
            content: `Agende serviços da Prefeitura Municipal de ${this.$t('CITY.name')} com muita praticidade.`,
          },
          {
            vmid: 'twitter:site',
            name: 'twitter:site',
            content: `${window.location.origin}`,
          },
          {
            vmid: 'twitter:title',
            name: 'twitter:title',
            content: `${this.$t('CITY.pageTitle')} ${this.$t('CITY.name')}`
          },
          {
            vmid: 'twitter:description',
            name: 'twitter:description',
            content: `Agende serviços da Prefeitura Municipal de ${this.$t('CITY.name')} com muita praticidade.`,
          },
           {
            vmid: 'twitter:image',
            name: 'twitter:image',
            content: require(`@/assets/theme/${process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft' ? 'upcities' : process.env.VUE_APP_KEYCLOAK_REALM}/icons/og/meta.jpg`),
          },
          {
            vmid: 'twitter:image:src',
            name: 'twitter:image:src',
            content: require(`@/assets/theme/${process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft' ? 'upcities' : process.env.VUE_APP_KEYCLOAK_REALM}/icons/og/meta.jpg`),
          },

          {
            vmid: 'name',
            itemprop: 'name',
            content: `${this.$t('CITY.pageTitle')} ${this.$t('CITY.name')}`
          },
          {
            vmid: 'description',
            itemprop: 'description',
            content: `Agende serviços da Prefeitura Municipal de ${this.$t('CITY.name')} com muita praticidade.`,
          },
          {
            vmid: 'image',
            itemprop: 'image',
            content: require(`@/assets/theme/${process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft' ? 'upcities' : process.env.VUE_APP_KEYCLOAK_REALM}/icons/og/meta.jpg`),
          },
        ],

        link: [
           {
            vmid: 'api',
            rel: 'preconnect',
            href: `${process.env.VUE_APP_API_ROUTE}`,
          },
          {
            vmid: 'conta',
            rel: 'preconnect',
            href: `${process.env.VUE_APP_KEYCLOAK_URL}`,
          },
          {
            vmid: 'minha-conta',
            rel: 'preconnect',
            href: `${process.env.VUE_APP_MINHACONTA_ROUTE}`,
          },
          {
            vmid: 'canonical',
            rel: 'canonical',
            href: `${window.location.origin}`,
          },
          {
            vmid: 'favicon',
            rel: 'icon',
            type: 'image/png',
            sizes: '16x16',
            href: require(`@/assets/theme/${process.env.VUE_APP_KEYCLOAK_REALM === 'Uppersoft' ? 'upcities' : process.env.VUE_APP_KEYCLOAK_REALM}/icons/favicon/favicon.png`),
          },
        ],
    };
  },

  async created() {
    this.appendUserwayAccessibilityBar();
    await this.includeGoogleMapsKey();
  },

  async mounted() {
    setTimeout(() => {
      let url = document.getElementById('policies-link');

      url === null ? '' :  url.href = this.privacyPoliciesURL;
    }, 2500);

    await this.includeClarity();
  },

  methods: {
    includeClarity(){
      let importScript = document.createElement('script');

      importScript.type = 'text/javascript';

      if(process.env.VUE_APP_CLARITY_TOKEN){
        importScript.onload = (function(c,l,a,r,i,t,y){
          c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments);};
          t=l.createElement(r);t.async=1;t.src=`https://www.clarity.ms/tag/${i}`;
          y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, 'clarity', 'script', process.env.VUE_APP_CLARITY_TOKEN);
      }
    },

    includeGoogleMapsKey() {
      let Script = document.createElement('script');

      Script.src = `https://maps.google.com/maps/api/js?key=${process.env.VUE_APP_MAPS_KEY}&libraries=places,marker`;
      Script.async = true;
      Script.setAttribute('defer', 'defer');

      document.body.appendChild(Script);
    },

    appendUserwayAccessibilityBar() {
      const uway = document.createElement('script');

      uway.setAttribute('type', 'text/javascript');
      uway.setAttribute(
        'src',
        'https://cdn.userway.org/widget.js'
      );
      uway.setAttribute('data-account', process.env.VUE_APP_USERWAY_ACCOUNT);
      document.querySelector('head').appendChild(uway);
    },
  }
};
</script>

<style lang="scss">
  /**
   *
   * Normalize from Tailwind
   * https://unpkg.com/tailwindcss@1.1.4/dist/base.css
   *
   */
  @import "@/assets/sass/base/_normalize.scss";

  /**
   *
   * Base
   *
   */
  @import "@/assets/sass/base/_container.scss";
  @import "@/assets/sass/base/_fonts.scss";
  @import "@/assets/sass/base/_variables.scss";
  @import "@/assets/sass/base/_transitions.scss";

  /**
   *
   * Project
   *
   */
  @import "@/assets/sass/project/_buttons.scss";
  @import "@/assets/sass/project/_form.scss";
  @import "@/assets/sass/project/_datepicker.scss";
  @import "@/assets/sass/project/_select.scss";
  @import "@/assets/sass/project/_radio.scss";

  html {
    scroll-behavior: smooth;
    overflow-x: hidden;

    body {
      font-family: $font-sans;
      color: $black;
      font-weight: $font-regular;
      font-size: $text-base;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      background-color: $gray-bg;

      // responsive svg
      svg {
        max-width: 100%;
        height: auto;
      }

      #app {
        min-height: 100vh;
      }

      &.blocked {
        overflow: hidden;
      }
    }
  }

  .modal-override {
    border-radius: 0.5rem !important;
  }

  .grecaptcha-badge {
    @include screen(phone-only) {
      margin-bottom: 8rem;
    }
  }

  body .uwy {
    // TODO: ajustar z-index para novo padrão
    z-index: 9999 !important;
  }

  body .uwy.userway_p1 .uai {
    left: calc(100% - 10px) !important;
    top: 10% !important;

    @include screen(tablet-big-up) {
      top: 15% !important;
    }
  }

  body div[vw] {
    top: 45% !important;
    z-index: 35 !important;

    @include screen(tablet-big-up) {
      top: 53% !important;
    }
  }
</style>
